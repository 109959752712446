import React, { useEffect } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import DefaultView from './pages/DefaultView/DefaultView';
import Login from './pages/Login/Login';
import NotFound from './pages/NotFound/NotFound';
import AudienceAffinity from './pages/Settings/AudienceAffinity/AudienceAffinity';
import ProjectSites from './pages/Settings/ProjectSites/ProjectSites';
import Rules from './pages/Settings/Rules/Rules';
import Settings from './pages/Settings/Settings';
import Users from './pages/Settings/Users/Users';
import Templates from './pages/Templates/Templates';
import routesNames from './routes/customRoutes';
import PrivateRoute from './routes/PrivateRoute';
import PublicRoute from './routes/PublicRoute';
import Prospecting from './pages/Prospecting/Prospecting';
import Unsubscribe from './pages/Unsubscribe/Unsubscribe';
import History from './pages/History/History';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const publicRoutes = {
    // path: routesNames.login,
    element: <PublicRoute />,
    children: [
      { path: routesNames.login, element: <Login /> },
      { path: routesNames.unsubscribe, element: <Unsubscribe /> },
    ],
  };

  const privateRoutes = {
    // path: routesNames.home,
    element: <PrivateRoute />,
    children: [
      { path: '*', element: <NotFound /> },
      { path: routesNames.home, element: <DefaultView /> },
      { path: routesNames.prospecting, element: <Prospecting /> },
      { path: routesNames.templates, element: <Templates /> },
      { path: routesNames.history, element: <History /> },
      { path: routesNames.settings, element: <Settings /> },
      { path: routesNames.users, element: <Users /> },
      { path: routesNames.audienceaff, element: <AudienceAffinity /> },
      { path: routesNames.projectSites, element: <ProjectSites /> },
      { path: routesNames.rules, element: <Rules /> },
      { path: routesNames.unsubscribe, element: <Unsubscribe /> },
    ],
  };

  const routing = useRoutes([publicRoutes, privateRoutes]);

  return <>{routing}</>;
};

export default App;
