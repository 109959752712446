import { EditObjectLink } from './Forms';

export interface LinkFormSidebarInterface {
  type: string;
}

export const LinkFormDefault = {
  url: '',
  status: 'pending',
  is_cannibalization: false,
  owner: 0,
  assignee: 0,
  site: 0,
  affinity: 0,
  is_assigned_by_manager: false,
  audience: 0,
  added_date: '',
  modified_date: '',
  template: 0,
  contacts: [
    {
      name: '',
      email: '',
      is_form: false,
      status: 'open',
      is_default: false,
      validation_status: 'active',
    },
  ],
};

export const ObjEditFormLink: EditObjectLink = {
  url: '',
  status: 'pending',
  is_cannibalization: false,
  owner: 0,
  assignee: 0,
  site: 0,
  affinity: 0,
  is_assigned_by_manager: false,
  audience: 0,
  template: 0,
  is_older_than_ninety_days: false,
  is_older_than_thirty_days: false,
  is_unsubscribed: false,
  unsubscribed_date: '',
  added_date: '',
  modified_date: '',
  site_name: '',
  contacts: [
    {
      name: '',
      email: '',
      is_form: false,
      status: 'open',
      is_default: true,
      validation_status: 'active',
    },
  ],
};

export const ContactsFormDefault = {
  name: '',
  email: '',
  is_form: false,
  status: 'open',
  is_default: true,
  validation_status: 'active',
};
