/* eslint-disable no-param-reassign */
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Chip,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Link,
  Modal,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { FlexBox, FlexBoxBetween } from '../../../components/tableItems';
import { SelectFormInput } from '../../../components/Forms/FormInputs';
import { BasicArray, SeverityType } from '../../../models/General';
import { FormButtons, MainButton } from '../../../components/buttons';
import { TextInputField } from '../../../components/inputs';
import { UserContextType } from '../../../models/User';
import Context from '../../../context/UserContext';
import { fetchAudiencesForForm } from '../../../helpers/audience';
import { fetchAffinitiesForForm } from '../../../helpers/affinities';
import {
  fetchTemplatesFilter,
  getTemplateTranslate,
} from '../../../helpers/templates';

import useLinkForm from '../../../stores/links/useLinkForm';
import PreviewEmail from './PreviewEmail';
import CustomToolbar from './CustomToolBar';
import { ObjectLinks } from '../../../models/Forms';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: { lg: 'translate(-60%, -50%)', xl: 'translate(-50%, -50%)' },
  width: { xs: '90%', sm: 600, md: 800, lg: 800, xl: 900 },
  maxHeight: { xs: '80vh', sm: '70vh', lg: '80vh', xl: '100vh' },
  overflowY: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  px: 2,
  pt: 2,
  pb: 3,
  zIndex: 1100,
};

type ContactsEmail = {
  name: string;
  id: number;
  contactName: string;
};

interface FormContactModalProps {
  open: boolean;
  closeModal: () => void;
  contactForm: ContactsEmail;
  handleFeedbackMessage: (message: string, severity?: SeverityType) => void;
  saveLink: (email?: boolean, copyLinkForm?: ObjectLinks) => Promise<void>;
  site: number;
  getNotesAndHistory?: () => Promise<[void, void]>;
}

type ArrayOptions = {
  name: string;
  id: number;
};

const sanitizeQuillContent = (content: string) => {
  return content.replace(/<p><br><\/p>/g, '\n');
};

const FormContactModal: React.FC<FormContactModalProps> = ({
  open,
  closeModal,
  contactForm,
  handleFeedbackMessage,
  saveLink,
  site,
  getNotesAndHistory,
}) => {
  const abortControllerAudience = useRef<AbortController | null>(null);
  const abortControllerAffinity = useRef<AbortController | null>(null);
  const abortControllerTemplate = useRef<AbortController | null>(null);
  const { linkForm } = useLinkForm();

  const [userAlias, setUserAlias] = useState<string | null>(null);

  const [openPreviewEmail, setOpenPreviewEmail] = useState(false);
  const closeModalPreview = () => setOpenPreviewEmail(false);
  const openModalPreview = () => setOpenPreviewEmail(true);

  const isFirstRender = useRef(true);

  const quillRef = useRef<ReactQuill | null>(null);

  const [loadingSend, setLoadingSend] = useState(false);

  const cancelRequestAud = () =>
    abortControllerAudience.current && abortControllerAudience.current.abort();

  const cancelRequestAff = () =>
    abortControllerAffinity.current && abortControllerAffinity.current.abort();

  const cancelRequestTemplate = () =>
    abortControllerTemplate.current && abortControllerTemplate.current.abort();

  const [loadingAud, setLoadingAud] = useState(false);
  const [loadingAff, setLoadingAff] = useState(false);
  const [loadingTemplates, setLoadingTemplates] = useState(false);

  const [audienceOptions, setAudienceOptions] = useState<ArrayOptions[]>([]);
  const [affinityOptions, setAffinityOptions] = useState<ArrayOptions[]>([]);
  const [templateOptions, setTemplateOptions] = useState<ArrayOptions[]>([]);

  const { UserContext } = useContext(Context) as UserContextType;
  const [body, setBody] = useState('');
  const [unsubscribeHtml, setUnsubscribeHtml] = useState('');
  const [signature, setSignature] = useState('');

  const [audienceSelected, setAudienceSelected] = useState(0);
  const [affinitySelected, setAffinitySelected] = useState(0);
  const [templateSelected, setTemplateSelected] = useState(0);

  const [loadingBody, setLoadingBody] = useState(false);

  const handleBodyChange = (content: string) => setBody(content);

  const cleanAll = () => {
    setUserAlias(null);
    setOpenPreviewEmail(false);
    setLoadingSend(false);
    setLoadingAud(false);
    setLoadingAff(false);
    setLoadingTemplates(false);
    setAudienceOptions([]);
    setAffinityOptions([]);
    setTemplateOptions([]);
    setBody('');
    setSignature('');
    setUnsubscribeHtml('');

    setAudienceSelected(0);
    setAffinitySelected(0);
    setTemplateSelected(0);
    setLoadingBody(false);
  };

  const transformContent = (content: string) => {
    return content.replace(/\n/g, '<br>');
  };

  const getBodyTemplate = async () => {
    setLoadingBody(true);
    try {
      const resp = await getTemplateTranslate(
        templateSelected,
        encodeURIComponent(linkForm.url),
        linkForm.id ?? 0,
        encodeURIComponent(contactForm.contactName ?? ''),
        contactForm.id,
      );
      if (resp.body === '') {
        handleFeedbackMessage('Template does not contain a message', 'error');
        return;
      }

      setBody(transformContent(`${resp.body}`));
      setUnsubscribeHtml(resp.unsubscribe_html);
      setSignature(resp.signature_html);
    } catch (err) {
      handleFeedbackMessage('Error while generating template', 'error');
      setBody('');
    } finally {
      setLoadingBody(false);
    }
  };

  useEffect(() => {
    if (templateSelected !== 0) getBodyTemplate();
  }, [templateSelected]);

  const getTemplates = async () => {
    setLoadingTemplates(true);
    setTemplateSelected(0);
    setTemplateOptions([]);
    cancelRequestTemplate();
    try {
      const resp = await fetchTemplatesFilter(
        audienceSelected,
        affinitySelected,
        site,
      );

      const tempsFilter = resp.filter(template => template.status === 'active');

      setTemplateOptions(
        tempsFilter.map(item => {
          return { id: item.id, name: item.title };
        }),
      );
    } catch (err) {
      setTemplateOptions([]);
    } finally {
      setLoadingTemplates(false);
    }
  };

  useEffect(() => {
    if (affinitySelected !== 0 && audienceSelected !== 0) getTemplates();
  }, [affinitySelected]);

  const getAffinities = async () => {
    setLoadingAff(true);
    setAffinitySelected(0);
    setTemplateSelected(0);
    setAffinityOptions([]);
    setTemplateOptions([]);
    cancelRequestAff();
    cancelRequestTemplate();

    abortControllerAffinity.current = new AbortController();
    try {
      const resp = await fetchAffinitiesForForm(
        audienceSelected,
        site,
        abortControllerAffinity.current.signal,
      );
      setAffinityOptions(resp);
    } catch (err) {
      setAffinityOptions([]);
    } finally {
      setLoadingAff(false);
    }
  };

  useEffect(() => {
    if (audienceSelected !== 0 && site !== 0) getAffinities();
  }, [audienceSelected]);

  const getAudiences = async () => {
    setLoadingAud(true);
    setAudienceOptions([]);
    setAffinityOptions([]);
    setTemplateOptions([]);
    cancelRequestAud();
    cancelRequestAff();
    if (site === 0) {
      setLoadingAud(false);
      return;
    }

    abortControllerAudience.current = new AbortController();
    try {
      const resp = await fetchAudiencesForForm(
        site,
        abortControllerAudience.current.signal,
      );
      setAudienceOptions(resp);
    } catch (err) {
      setAudienceOptions([]);
    } finally {
      setLoadingAud(false);
    }
  };

  useEffect(() => {
    getAudiences();
  }, [site]);

  useEffect(() => {
    return () => {
      setAudienceOptions([]);
      setAffinityOptions([]);
      setTemplateOptions([]);
    };
  }, []);

  const handleUndo = () => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      editor.history.undo();
    }
  };

  const handleRedo = () => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      editor.history.redo();
    }
  };

  const stripHtmlTags = (html: string) => {
    return html
      .replace(/<p>/g, '\n') // Replace paragraph tags with double line breaks
      .replace(/<\/p>/g, '')
      .replace(/<br\s*\/?>/g, '\n')
      .replace(/<\/?[^>]+(>|$)/g, '')
      .trim();
  };

  const closeTheModal = () => {
    cleanAll();
    closeModal();
  };

  const save = async () => {
    navigator.clipboard.writeText(
      stripHtmlTags(
        `${sanitizeQuillContent(body)}\n${signature}\n\n${unsubscribeHtml}`,
      ),
    );
    setLoadingSend(true);
    try {
      const copyLink = JSON.parse(JSON.stringify(linkForm));
      const arrayOfContacts = [...linkForm.contacts];
      const findContactIndex = arrayOfContacts.findIndex(
        contact => contact.id && contact.id === contactForm.id,
      );
      if (findContactIndex >= 0) {
        const rawContent = quillRef.current?.getEditor().root.innerHTML || '';
        const sanitizedContent = sanitizeQuillContent(rawContent);

        arrayOfContacts[findContactIndex] = {
          ...arrayOfContacts[findContactIndex],
          form_text: `${sanitizedContent}\n${sanitizeQuillContent(
            signature,
          )}\n\n${sanitizeQuillContent(unsubscribeHtml)}`,
        };
        copyLink.text_note = `Form sent:\n${`${sanitizedContent}\n${sanitizeQuillContent(
          signature,
        )}\n\n${sanitizeQuillContent(unsubscribeHtml)}`}`;

        copyLink.contacts = [...arrayOfContacts];
        copyLink.affinity = affinitySelected;
        copyLink.audience = audienceSelected;
        copyLink.template = templateSelected;
        copyLink.status = 'pending';
        await saveLink(undefined, copyLink);
        if (getNotesAndHistory) getNotesAndHistory();
        closeTheModal();
        return;
      }
      handleFeedbackMessage('Error updating link', 'error');
    } catch (err) {
      handleFeedbackMessage('Error updating link', 'error');
    } finally {
      setLoadingSend(false);
    }
  };

  return (
    <Modal
      open={open}
      disableAutoFocus
      disableEnforceFocus
      disableEscapeKeyDown
      disableRestoreFocus
    >
      <Box sx={style}>
        <FlexBoxBetween>
          <Typography fontWeight={600} fontSize={17} textTransform="capitalize">
            Update Contact Form
          </Typography>
          <IconButton
            onClick={() => {
              closeTheModal();
            }}
            sx={{ fontSize: 22, border: 1 }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </FlexBoxBetween>
        <Divider sx={{ mb: 2, mt: 1 }} />

        <FlexBox gap={1} maxWidth="20px">
          <Typography fontSize={15} fontWeight={600}>
            Link:
          </Typography>
          <Link
            href={linkForm.url}
            target="_blank"
            color="#373737"
            underline="hover"
            fontSize={14}
          >
            {linkForm.url}
          </Link>
        </FlexBox>

        <Box mt={2}>
          <FlexBox mt={2} gap={1}>
            <Typography fontSize={14} mr={1}>
              Contact Name:
            </Typography>
            <Chip
              sx={{
                fontWeight: 700,
              }}
              label={contactForm.contactName}
              variant="outlined"
            />
          </FlexBox>
          <FlexBox mt={2} gap={1} maxWidth="80%">
            <Typography fontSize={14} mr={1}>
              Contact URL:
            </Typography>
            <Chip
              sx={{
                fontWeight: 700,
                textOverflow: 'clip',
              }}
              label={contactForm.name}
              variant="outlined"
            />
          </FlexBox>
          <Grid container spacing={2} width="100%" mt={0.5}>
            <Grid item xs={4}>
              <SelectFormInput
                loading={loadingAud}
                required={false}
                clearIcon
                error={false}
                onBlur={value => {}}
                options={audienceOptions}
                disabled={loadingAud || audienceOptions.length === 0}
                placeholder="Audience"
                value={
                  audienceOptions.find(
                    item => item.id === audienceSelected,
                  ) ?? {
                    id: 0,
                    name: '',
                  }
                }
                onChange={(value: BasicArray | null) =>
                  setAudienceSelected(value ? (value.id as number) : 0)
                }
              />
            </Grid>{' '}
            <Grid item xs={4}>
              <SelectFormInput
                loading={loadingAff}
                required={false}
                clearIcon
                error={false}
                onBlur={value => {}}
                options={affinityOptions}
                disabled={loadingAff || affinityOptions.length === 0}
                placeholder="Affinity"
                value={
                  affinityOptions.find(
                    item => item.id === affinitySelected,
                  ) ?? {
                    id: 0,
                    name: '',
                  }
                }
                onChange={(value: BasicArray | null) =>
                  setAffinitySelected(value ? (value.id as number) : 0)
                }
              />
            </Grid>{' '}
            <Grid item xs={4}>
              <SelectFormInput
                loading={loadingTemplates}
                required={false}
                clearIcon
                error={false}
                onBlur={value => {}}
                options={templateOptions}
                disabled={templateOptions.length === 0 || loadingTemplates}
                placeholder="Template"
                value={
                  templateOptions.find(
                    item => item.id === templateSelected,
                  ) ?? {
                    id: 0,
                    name: '',
                  }
                }
                onChange={(value: BasicArray | null) =>
                  setTemplateSelected(value ? (value.id as number) : 0)
                }
              />
            </Grid>
          </Grid>

          <Box mt={3}>
            <CustomToolbar onRedo={handleRedo} onUndo={handleUndo} />
            <ReactQuill
              theme="snow"
              style={{ minHeight: '200px', fontSize: '13px' }}
              className="my-custom-editor"
              readOnly={loadingBody}
              ref={quillRef}
              value={body}
              onChange={handleBodyChange}
              placeholder="Compose your message..."
              modules={{
                toolbar: {
                  container: '#custom-toolbar',
                  // handlers: customHandlers,
                },

                history: {
                  delay: 1000,
                  maxStack: 50,
                  userOnly: true,
                },
              }}
            />
          </Box>
          <FlexBox mt={2} gap={2}>
            <MainButton
              variant="contained"
              onClick={openModalPreview}
              disabled={body === '' || loadingBody}
              loading={false}
              startIcon={<RemoveRedEyeIcon />}
            >
              Preview
            </MainButton>

            <MainButton
              variant="contained"
              onClick={save}
              disabled={
                audienceSelected === 0 ||
                affinitySelected === 0 ||
                templateSelected === 0 ||
                body === '' ||
                loadingBody
              }
              loading={loadingSend}
              sx={{
                background: '#BAF372',

                color: 'black',
                '&:hover': {
                  backgroundColor: '#BAF372',
                },
              }}
            >
              Save & Copy to Clipboard
            </MainButton>
          </FlexBox>
        </Box>

        <PreviewEmail
          open={openPreviewEmail}
          closeModal={closeModalPreview}
          quillRef={quillRef}
          title="Note Preview"
          body={`${body}<br>${signature}<br>${unsubscribeHtml}`}
        />
      </Box>
    </Modal>
  );
};

export default FormContactModal;
