/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import React, { useContext, useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';
import RightColumns from '../../../components/Columns/RightColumns';
import MainForm from './EditLink.tsx/MainForm';
import useSidebar from '../../../stores/links/useSidebar';
import LinkDetails from './AddLink/LinkDetails';
import useOwners from '../../../customHooks/useOwners';
import useLinkStatus from '../../../customHooks/useLinkStatus';
import useLinkForm from '../../../stores/links/useLinkForm';
import Context from '../../../context/UserContext';
import { UserContextType } from '../../../models/User';
import { GlobalContextType } from '../../../models/Context';
import GeneralContext from '../../../context/GeneralActions';
import useSites from '../../../customHooks/useSites';
import ContactsAdd from './AddLink/ContactsAdd';
import FeedbackMessage from '../../../components/FeedbackMessage';
import { MessagesInt, SeverityType } from '../../../models/General';
import {
  createLink,
  fetchAllContacts,
  fetchLinkHistory,
  fetchLinkNotes,
  sendErrorSubmit,
  updateLink,
} from '../../../helpers/links';
import { ContactsFormDefault, LinkFormDefault } from '../../../models/LinkForm';
import AddWarningLink from '../AddWarningLink';
import AddFormHeader from './AddLink/AddFormHeader';
import { IS_USER_ADMIN } from '../../../constants/user';
import LinkEmailSave from './LinkEmailSave';
import useLinksList from '../../../stores/links/useLinksList';
import { ObjectLinks } from '../../../models/Forms';
import ClaimContainer from './ClaimLink/ClaimContainer';
import useSiteStore from '../../../stores/sites/sites';
import ContactsEdit from './EditLink.tsx/ContactsEdit';
import useUserProfile from '../../../stores/user/useUserProfile';
import useAllUsers from '../../../customHooks/useAllUsers';
import FormContactModal from './FormContactModal';
import { HistoryReq, NotesReq } from '../../../models/Notes';

interface FormBoxProps {
  tabView: number;
}

const FormBox: React.FC<FormBoxProps> = ({ tabView }) => {
  const { userSite } = useSiteStore();
  const { linksList, updateLinksList } = useLinksList();
  // GLOBAL VARIABLES WITH CONTEXT
  const { UserContext } = useContext(Context) as UserContextType;
  const { GlobalContext, setGlobalContext } = useContext(
    GeneralContext,
  ) as GlobalContextType;
  const { getUserEmailAlias } = useUserProfile();

  // GLOBAL VARIABLES ZUSTAND
  const { formSidebar, updateSidebar } = useSidebar();
  const {
    linkForm,
    replaceLinkForm,
    replaceObjEditForm,
    updateTabForm,
    loadingForm,
    updateLoadingForm,
    contactsValues,
    linkFormErrors,
    editLinkForm,
  } = useLinkForm();

  // CUSTOM HOOKS FOR THE ENDPOINTS
  const { owners, loading: loadingOwners } = useAllUsers();
  const { statusOptions, loadingStatusOptions } = useLinkStatus();
  const { sites: sitesOptions, loading: loadingSites } = useSites({
    userId: UserContext.id,
    minimal: true,
  });

  const [openWarning, setOpenWarning] = useState(false);
  const closeWarning = (value: boolean) => setOpenWarning(value);

  const [activeStep, setActiveStep] = useState(0);

  const [stepOneFormValid, setStepOneFormValid] = useState(false);
  const [stepTwoFormValid, setStepTwoFormValid] = useState(true);

  const [openMessage, setOpenMessage] = useState(false);
  const [messagesProps, setMessagesProps] = useState<MessagesInt>({
    message: '',
    severity: 'error',
  });

  const [openModalEmail, setOpenModalEmail] = useState(false);
  const handleOpenModalEmail = (value: boolean) => setOpenModalEmail(value);

  const [openFormContact, setOpenFormContact] = useState(false);
  const handleOpenFormContact = (value: boolean) => {
    setOpenFormContact(value);
  };
  const [contactForm, setContactForm] = useState({
    id: 0,
    name: '',
    contactName: '',
  });
  const [siteForm, setSiteForm] = useState(0);

  const [isOpenModalEmailOpen, setIsOpenModalEmailOpen] = useState(false);
  const updateIsOpenModalEmailOpen = (value: boolean) =>
    setIsOpenModalEmailOpen(value);

  const [notes, setNotes] = useState<NotesReq[]>([]);
  const updateNotes = (notesUpdated: NotesReq[]) => setNotes(notesUpdated);
  const [loadingNotes, setLoadingNotes] = useState(false);

  const [history, setHistory] = useState<HistoryReq[]>([]);
  const updateHistory = (historyUpdated: HistoryReq[]) =>
    setHistory(historyUpdated);
  const [loadingHistory, setLoadingHistory] = useState(false);

  const [emailSave, setEmailSave] = useState<{
    site: number;
    contacts: { id: number; name: string; email: string; default: boolean }[];
    url: string;
    linkId: number;
    userEmail: string | null;
  }>({ site: 0, contacts: [], url: '', linkId: 0, userEmail: null });

  const handleFeedbackMessage = (message: string, severity?: SeverityType) => {
    setOpenMessage(true);
    setMessagesProps({ message, severity: severity ?? 'error' });
  };

  const getContactsFromLink = async (id: number) => {
    try {
      const contactsLink = await fetchAllContacts([id]);
      return contactsLink[0].contacts_links.length === 0
        ? []
        : contactsLink[0].contacts_links;
    } catch (err) {
      return [];
    }
  };
  const saveLink = async (email?: boolean, copyLinkForm?: ObjectLinks) => {
    updateLoadingForm(true);
    const copyObj = copyLinkForm ? { ...copyLinkForm } : { ...linkForm };

    copyObj.send_email = !!(
      email && linkForm.contacts.find(item => item.is_default && !item.is_form)
    );
    try {
      const resp = await createLink(copyObj);

      const contactsFromLink = await getContactsFromLink(resp.id);
      if (email) {
        if (linkForm.contacts.find(item => item.is_default && item.is_form)) {
          const findContact = contactsFromLink.find(
            item => item.is_default && item.is_form,
          );
          if (findContact) {
            setContactForm({
              id: findContact.id,
              name: findContact.form_url ?? findContact.name,
              contactName: findContact.name ?? '',
            });
            setSiteForm(copyObj.site);
          }
          handleOpenFormContact(true);
        } else {
          const listContacts = linkForm.contacts.filter(
            user => user.email !== '' && !user.is_form,
          );
          const findContacts = contactsFromLink.filter(contact =>
            listContacts.some(item => item.email === contact.email),
          );

          const listOfContacts = findContacts.map(item => {
            return {
              email: item.email,
              name: item.name,
              id: item.id ?? 0,
              default: item.is_default ?? false,
            };
          });

          const respAlias = await getUserEmailAlias(linkForm.site);

          setEmailSave({
            site: linkForm.site,
            contacts: listOfContacts,
            url: linkForm.url,
            linkId: resp.id ?? 0,
            userEmail: respAlias,
          });
          handleOpenModalEmail(true);
          updateIsOpenModalEmailOpen(true);
        }
      }
      handleFeedbackMessage(`Link created successfully`, 'success');
      // const copyArr = [...linksList];
      // if (copyArr.length >= GlobalContext.links.page_size) {
      //   copyArr.pop();
      // }
      // updateLinksList([resp, ...copyArr]);
      setGlobalContext({
        ...GlobalContext,
        links: { ...GlobalContext.links, page: GlobalContext.links.page },
      });
      const obj: ObjectLinks = {
        id: resp.id,
        url: resp.url,
        site: linkForm.site,
        found_date: resp.found,
        audience: 0,
        affinity: 0,
        contacts: contactsFromLink,
        is_assigned_by_manager: linkForm.is_assigned_by_manager ?? false,
        status: linkForm.status,
        is_cannibalization: resp.is_cannibalization,
        owner: linkForm.owner,
        template: 0,
        assignee: linkForm.owner,
      };
      replaceObjEditForm(
        {
          ...obj,
          site_name: resp.site.name,
          is_older_than_ninety_days: resp.is_older_than_ninety_days,
          is_older_than_thirty_days: resp.is_older_than_thirty_days,
          is_unsubscribed: resp.is_unsubscribed,
          unsubscribed_date: resp.unsubscribed_date,
          added_date: resp.created,
          modified_date: resp.modified,
        },
        obj,
        IS_USER_ADMIN.includes(UserContext.profile.role),
      );
      updateTabForm(0);
      updateSidebar({ type: email ? 'edit' : 'read' });
    } catch (err) {
      const error = err as any;
      const url = `POST ${process.env.REACT_APP_API_BASE_URL}/api/v2/links/`;
      await sendErrorSubmit({
        request_url: url,
        request_payload: JSON.stringify(linkForm),
        response: JSON.stringify(error.data),
        token: UserContext.token,
      });
      console.log(error.data);
      handleFeedbackMessage(`Error creating link`, 'error');
    } finally {
      updateLoadingForm(false);
    }
  };

  const updateContactLink = async (
    email?: boolean,
    copyLinkForm?: ObjectLinks,
  ) => {
    // setLoading(true);
    const obj = copyLinkForm ?? linkForm;

    try {
      let linkID: number = 0;

      const resp = await updateLink(obj, obj.id ?? 0);
      linkID = resp.id;

      handleFeedbackMessage(`Link updated successfully`, 'success');
      setGlobalContext({
        ...GlobalContext,
        links: { ...GlobalContext.links, page: GlobalContext.links.page },
      });
      // const copyArr = [...linksList];
      // updateLinksList(
      //   copyArr.map(item =>
      //     item.id === response.id ? { ...item, ...response } : item,
      //   ),
      // );
      const contactsFromLink = await getContactsFromLink(resp.id);

      updateSidebar({ type: 'read' });
      const objectlink: ObjectLinks = {
        id: resp.id,
        url: resp.url,
        site: linkForm.site,
        is_assigned_by_manager: linkForm.is_assigned_by_manager ?? false,
        found_date: resp.found,
        audience: obj.audience,
        affinity: obj.affinity,
        contacts: contactsFromLink,
        status: resp.status,
        is_cannibalization: resp.is_cannibalization,
        owner: linkForm.owner,
        template: obj.template,
        assignee: linkForm.owner,
      };
      replaceObjEditForm(
        {
          ...obj,
          site_name: resp.site.name,
          is_older_than_ninety_days: resp.is_older_than_ninety_days,
          is_older_than_thirty_days: resp.is_older_than_thirty_days,
          is_unsubscribed: resp.is_unsubscribed,
          unsubscribed_date: resp.unsubscribed_date,
          added_date: resp.created,
          modified_date: resp.modified,
        },
        objectlink,
        IS_USER_ADMIN.includes(UserContext.profile.role),
      );
    } catch (err) {
      const error = err as any;
      const url = `PUT ${process.env.REACT_APP_API_BASE_URL}/api/v2/links/${
        obj.id ?? 0
      }}`;
      await sendErrorSubmit({
        request_url: url,
        request_payload: JSON.stringify(obj),
        response: JSON.stringify(error.data),
        token: UserContext.token,
      });
      console.log(error.data);
      handleFeedbackMessage(`Error updating link`, 'error');
    } finally {
      // setLoading(false);
    }
  };

  const handleEmailSent = () => {
    if (linkForm.contacts.filter(item => item.is_default).length === 0) {
      handleFeedbackMessage(
        'At least one contact included in template is required',
        'error',
      );

      return;
    }
    if (linkForm.contacts.filter(item => item.is_default).length > 1) {
      handleFeedbackMessage(
        'You can only have one contact included in template',
        'error',
      );

      return;
    }
    saveLink(true);
  };

  useEffect(() => {
    if (formSidebar.type === 'add' && activeStep !== 0) {
      setActiveStep(0);
      setStepOneFormValid(false);
      setStepTwoFormValid(true);
    }
  }, [formSidebar]);

  const validateClear = () => {
    const { contacts } = linkForm;

    if (contacts.length > 1 || linkForm.url !== '') return true;

    if (contacts.length === 1) {
      const singleContact = contacts[0];

      const isEqual =
        JSON.stringify(ContactsFormDefault) === JSON.stringify(singleContact);

      return !isEqual;
    }

    return false;
  };

  const clearForm = () => {
    const clearedForm = { ...LinkFormDefault };
    clearedForm.owner = UserContext.id;
    clearedForm.assignee = UserContext.id;
    clearedForm.site = userSite ?? 0;

    replaceLinkForm(clearedForm);

    setActiveStep(0);
    closeWarning(false);
  };

  const findSiteForm = () => {
    // if (IS_USER_ADMIN.includes(UserContext.profile.role)) return true;
    const siteInfo = sitesOptions.find(item => item.id === linkForm.site);
    if (siteInfo) return siteInfo.form_contacts_allowed;

    return false;
  };

  const isFormValid = (): boolean => {
    const contactsLoading = contactsValues.some(contact => contact.loading);

    const contactsErrors = linkFormErrors.contacts.some(contactError =>
      Object.values(contactError).some(error => error),
    );

    const contactsValid = linkForm.contacts.every((contact, index) => {
      const isForm = contact.is_form;
      return (
        (isForm
          ? contact.name && contact.form_url
          : contact.email && contactsValues[index].validEmail === true) &&
        contact.status
      );
    });

    return contactsValid && !contactsErrors && !contactsLoading;
  };

  const getHistory = async () => {
    setLoadingHistory(true);

    try {
      const fetchHistory = await fetchLinkHistory(editLinkForm.id ?? 0);
      setHistory(fetchHistory);
    } catch (err) {
      setHistory([]);
    } finally {
      setLoadingHistory(false);
    }
  };

  const getNotes = async () => {
    setLoadingNotes(true);
    try {
      const fetchNotes = await fetchLinkNotes(editLinkForm.id ?? 0);
      setNotes(fetchNotes);
    } catch (err) {
      setNotes([]);
    } finally {
      setLoadingNotes(false);
    }
  };

  const getNotesAndHistory = async () =>
    Promise.all([getNotes(), getHistory()]);

  return (
    <Box
      position="relative"
      height="100vh"
      overflow={loadingForm ? 'hidden' : isOpenModalEmailOpen ? 'scroll' : ''}
      sx={
        isOpenModalEmailOpen || openModalEmail
          ? { backgroundColor: 'white', zIndex: 5000 }
          : {}
      }
    >
      <RightColumns pb={2}>
        <Typography
          fontWeight={700}
          fontSize={15}
          marginBottom={1.5}
          textTransform="capitalize"
          textAlign="center"
        >
          {formSidebar.type === 'add' && 'Create Link'}
          {(formSidebar.type === 'edit' ||
            formSidebar.type === 'read' ||
            formSidebar.type === 'load') &&
            tabView < 2 &&
            'Link Details'}
        </Typography>
        {/* {(formSidebar.type === 'claim' ||
          formSidebar.type === 'claim-read') && (
          <Typography
            fontWeight={700}
            fontSize={15}
            marginBottom={1.5}
            textTransform="capitalize"
            textAlign="center"
          >
            Claim Link
          </Typography>
        )} */}

        {/* EDIT LINKS */}
        {(formSidebar.type === 'edit' ||
          formSidebar.type === 'read' ||
          formSidebar.type === 'load') &&
          tabView < 2 && (
            <MainForm
              handleFeedbackMessage={handleFeedbackMessage}
              ownersField={{
                owners: owners.map(item => {
                  return { id: item.id, name: item.username };
                }),
                loadingOwners,
              }}
              sitesField={{ sitesOptions, loadingSites }}
              statusField={{ statusOptions, loadingStatusOptions }}
              updateIsOpenModalEmailOpen={updateIsOpenModalEmailOpen}
              isOpenModalEmailOpen={isOpenModalEmailOpen}
              tabView={tabView}
              historyObj={{
                history,
                updateHistory,
                loadingHistory,
                getHistory,
              }}
              notesObj={{ notes, updateNotes, loadingNotes, getNotes }}
            />
          )}

        {(formSidebar.type === 'claim' || tabView >= 2) && (
          <ClaimContainer
            owners={owners}
            sites={sitesOptions}
            handleFeedbackMessage={handleFeedbackMessage}
            tabView={tabView}
          />
        )}

        {/* ADD LINKS */}

        {formSidebar.type === 'add' && tabView < 2 && (
          <>
            <AddFormHeader
              activeStep={activeStep}
              handleEmailSent={handleEmailSent}
              saveLink={saveLink}
              updateStep={(step: number) => setActiveStep(step)}
              loadingForm={loadingForm}
              stepOneFormValid={stepOneFormValid}
              stepTwoFormValid={!isFormValid()}
              updateOpenWarning={(value: boolean) => setOpenWarning(value)}
              validateClear={!validateClear()}
            />

            <Divider />
            <Box
              sx={{
                visibility: activeStep === 0 ? 'visible' : 'hidden',
                height: activeStep === 0 ? 'auto' : 0,
              }}
            >
              <LinkDetails
                ownersField={{
                  owners,
                  loadingOwners,
                }}
                statusField={{ statusOptions, loadingStatusOptions }}
                sitesField={{ sitesOptions, loadingSites }}
                onFormValidityChange={setStepOneFormValid}
              />
            </Box>
            <Box
              sx={{
                visibility: activeStep === 1 ? 'visible' : 'hidden',
                height: activeStep === 1 ? 'auto' : 0,
              }}
            >
              <ContactsEdit
                handleFeedbackMessage={handleFeedbackMessage}
                allowOpenNewModalContact={false}
                openNewContactModal={() => {}}
                doNotDeleteContact=""
                form_contacts_allowed={findSiteForm()}
                getNotes={() => {}}
              />
              {/* <ContactsAdd
                handleFeedbackMessage={handleFeedbackMessage}
                onContactsValidityChange={setStepTwoFormValid}
                stepTwoFormValid={stepTwoFormValid}
                sitesField={{ sitesOptions, loadingSites }}
              /> */}
            </Box>
          </>
        )}
      </RightColumns>
      {loadingForm && (
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: theme => theme.zIndex.drawer + 1,
            position: 'absolute',
            top: 65,
            left: 0,
            width: '100%',
            height: '100%',
          }}
          open={loadingForm}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <FeedbackMessage
        open={openMessage}
        setOpen={setOpenMessage}
        vertical="top"
        horizontal="right"
        severity={messagesProps.severity}
        message={messagesProps.message}
      />
      <AddWarningLink
        open={openWarning}
        closeModal={closeWarning}
        body="If you decide to continue, your updates will not be saved"
        continueModal={() => {
          clearForm();
        }}
      />

      <LinkEmailSave
        open={openModalEmail}
        closeModal={() => {
          handleOpenModalEmail(false);
          updateIsOpenModalEmailOpen(false);
        }}
        contacts={emailSave.contacts}
        site={emailSave.site}
        userEmail={emailSave.userEmail}
        url={emailSave.url}
        saveLink={saveLink}
        linkId={emailSave.linkId}
        handleFeedbackMessage={handleFeedbackMessage}
      />

      <FormContactModal
        open={openFormContact}
        closeModal={() => handleOpenFormContact(false)}
        contactForm={contactForm}
        site={siteForm}
        saveLink={updateContactLink}
        handleFeedbackMessage={handleFeedbackMessage}
      />
    </Box>
  );
};

export default FormBox;
